import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import {IResetPasswordRequest, IValamarPartner} from "../customer/interfaces/customer-valamar-interface";
import {MessageService} from "primeng/api";
import {DynamicDialogConfig} from 'primeng/dynamicdialog';


@Component({
  selector: 'app-reset-dialog',
  templateUrl: './reset-dialog.component.html',
  styleUrls: ['./reset-dialog.component.scss']
})
export class ResetDialogComponent implements OnInit {

  email: string = this.config.data.customer.email;

  constructor(
    public ref: DynamicDialogRef,
    public messageService: MessageService,
    public config: DynamicDialogConfig
  ) {
  }

  ngOnInit () {

  }

  sendResetRequest() {
    const email = this.config.data.customer.email;
    let language = this.config.data.customer.language
    language = this.config.data.customer.language ? this.config.data.customer.language : 'en';
    const name = this.config.data.customer.name;
    const resetPasswordRequestData: IResetPasswordRequest = {
      to: email,
      name: name,
      link: "",
      language: language
    }
    this.ref.close(resetPasswordRequestData);
  }
}
