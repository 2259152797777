import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalInterceptor, MsalService } from '@azure/msal-angular';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable()
export class AppInterceptor implements HttpInterceptor {

  constructor(
    private _authService: MsalService,
    private _msalInterceptor: MsalInterceptor,
    private _r:Router
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var authReq = req.clone();
    authReq = this.addAuthHeader(authReq);
    return this._msalInterceptor.intercept(authReq, next).pipe(
      catchError(err => {
        return this.handleMsalResponseError(err);
      }));
  }
  private addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      headers: request.headers
        .set(environment.APIM.SubscriptionKeyName,environment.APIM.SubscriptionKeyValue)
        .set("content-type", "application/json")
    });
  }
  private handleMsalResponseError(err: HttpErrorResponse) {
    const er = { ...err };

    if (err.status === 401) {
      er.message = err?.error?.Error;
      this._authService.logoutRedirect({
        postLogoutRedirectUri: environment.postLogoutUrl,
      });
    }
    else if (err.status === 500) {
      er.message = err?.error?.Error;
    }
    else if (err.status === 400) {
      er.message = err?.error?.Error;
    }
    else if (err.status === 402 || err.status === 403 || err.status === 404 || err.status === 409) {
      er.message = err?.error?.Error;
      localStorage.clear();
      this._r.navigate(['login']);
    }
    else {
      er.message = err?.error?.Error;
    }
    return throwError(() => er);
  }
}
