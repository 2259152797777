export const customersMongoData = [
  {
    _id: {
      $oid: "6500d6d0a6107f3fc7759826"
    },
    masterId: "1250296a-104f-4392-9f96-08d577eccd51",
    isActive: true,
    isDeleted: false,
    createdBySystem: "Valamar.com",
    createdDate: {
      $date: "2023-09-12T21:23:27.730Z"
    },
    modifiedBySystem: null,
    modifiedDate: null,
    version: 1,
    mappingFields: {
      OPERA: {
        ar_code: ""
      }
    },
    name: "Kesari Tours Travels",
    country: "IN",
    OIB: "12ABCDE3456FGZH",
    phone: "+919512345678",
    fax: "+919512345678",
    email: "valabii.hr@gmail.com",
    address: "314, L.J. Road, Mahim",
    city: "Mumbai",
    postalCode: "40016",
    URL: "https://www.kesari.in"
    ,
    responsiblePerson: "Shweta",
    bankAccount: {
      bank: "ICICI Bank",
      centralOffice: "Fort, Mumbai",
      IBAN: "IN7224840085543415387"
    },
    isApproved: true
  },
  {
    _id: {
      $oid: "6500d6d0a6107f3fc7759826"
    },
    masterId: "1250296a-104f-4392-9f96-08d577eccd51",
    isActive: true,
    isDeleted: false,
    createdBySystem: "Valamar.com",
    createdDate: {
      $date: "2023-09-12T21:23:27.730Z"
    },
    modifiedBySystem: null,
    modifiedDate: null,
    version: 1,
    mappingFields: {
      OPERA: {
        ar_code: ""
      }
    },
    name: "Kesari Tours Travels",
    country: "IN",
    OIB: "12ABCDE3456FGZH",
    phone: "+919512345678",
    fax: "+919512345678",
    email: "valabii.hr@gmail.com",
    address: "314, L.J. Road, Mahim",
    city: "Mumbai",
    postalCode: "40016",
    URL: "https://www.kesari.in"
    ,
    responsiblePerson: "Shweta",
    bankAccount: {
      bank: "ICICI Bank",
      centralOffice: "Fort, Mumbai",
      IBAN: "IN7224840085543415387"
    },
    isApproved: false
  },
];

export const valamarCustomersTableData = [
  {
    id: "6500d6d0a6107f3fc7759826",
    name: "Kesari Tours Travels",
    email: "valabii.hr@gmail.com",
    address: "314, L.J. Road, Mahim",
    postalCode: "40016",
    city: "Delhi",
    country: "IN",
    oib: "12ABCDE3456FGZH",
    phone: "+919512345678",
    url: "https://www.kesari.in",
    responsiblePerson: "Shweta",
    isApproved: true
  },
  {
    id: "6500d6d0a6107f3fc7759827",
    name: "Kesari Tours Travels",
    email: "valabii.hr@gmail.com",
    address: "314, L.J. Road, Mahim",
    postalCode: "40016",
    city: "Salzburg",
    country: "AT",
    oib: "12ABCDE3456FGZH",
    phone: "+919512345678",
    url: "https://www.kesari.in",
    responsiblePerson: "Shweta",
    isApproved: true
  },
  {
    id: "6500d6d0a6107f3fc7759828",
    name: "Kesari Tours Travels",
    email: "valabii.hr@gmail.com",
    address: "314, L.J. Road, Mahim",
    postalCode: "40016",
    city: "Antwerp",
    country: "BE",
    oib: "12ABCDE3456FGZH",
    phone: "+919512345678",
    url: "https://www.kesari.in",
    responsiblePerson: "Shweta",
    isApproved: false
  },
  {
    id: "6500d6d0a6107f3fc7759829",
    name: "Kesari Tours Travels",
    email: "valabii.hr@gmail.com",
    address: "314, L.J. Road, Mahim",
    postalCode: "40016",
    city: "Rome",
    country: "IT",
    oib: "12ABCDE3456FGZH",
    phone: "+919512345678",
    url: "https://www.kesari.in",
    responsiblePerson: "Shweta",
    isApproved: false
  },
  {
    id: "6500d6d0a6107f3fc77598210",
    name: "Kesari Tours Travels",
    email: "valabii.hr@gmail.com",
    address: "314, L.J. Road, Mahim",
    postalCode: "40016",
    city: "Nikosia",
    country: "CY",
    oib: "12ABCDE3456FGZH",
    phone: "+919512345678",
    url: "https://www.kesari.in",
    responsiblePerson: "Shweta",
    isApproved: false
  }


]
