import {Component, OnInit} from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import {FormControl, FormGroup, Validators } from "@angular/forms";
import {IValamarPartner} from "../customer/interfaces/customer-valamar-interface";
import {MessageService} from "primeng/api";
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {IInvitationEmail, ILanguage} from "./interfaces/invite.email.interface";

@Component({
  selector: 'app-invite-dialog',
  templateUrl: './invite-dialog.component.html',
  styleUrls: ['./invite-dialog.component.scss'],
  providers: [DialogService]
})
export class InviteDialogComponent implements OnInit {

  constructor(
    public ref: DynamicDialogRef,
    public messageService: MessageService,
    public config: DynamicDialogConfig
  ) {
  }

  languageTypes: ILanguage[] = [];

  ngOnInit() {

    this.languageTypes = [
      {label: 'Hrvatski', value: 'hr'},
      {label: 'Engleski', value: 'en'},

    ];
  }

  get languageControl() {
    return this.formGroup.controls.type;
  }

  get nameControl() {
    return this.formGroup.controls.name;
  }

  get emailControl() {
    return this.formGroup.controls.email;
  }

  formGroup = new FormGroup({
    type: new FormControl(this.languageTypes, { nonNullable: true }),
    name: new FormControl('',),
    email: new FormControl('', [Validators.email, Validators.required]),
  });

  sendMail() {
    const lang: string = (this.languageControl.value.length === 0) ? 'HR' : this.languageControl.value.toString();
    const emailAddress: string = (this.emailControl.value === null) ? '' : this.emailControl.value.toString();
    const name: string = (this.nameControl.value === null) ? '' : this.nameControl.value.toString();

    const emailData: IInvitationEmail = {
      name: name,
      to: emailAddress,
      language: lang,
    }
    // console.log('INVITE DIALOG email data ', emailData);
    this.ref.close(emailData);
  }
}
