import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService
} from "@azure/msal-angular";
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication
} from '@azure/msal-browser';
import { ClipboardModule, ClipboardService } from 'ngx-clipboard';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RippleModule } from "primeng/ripple";
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { ToastModule } from "primeng/toast";
import { environment } from 'src/environments/environment';
import { AppMsalRedirectComponent } from './app-msal-redirect.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { CustomerFormComponent } from './components/customer/customer-form/customer-form.component';
import { CustomerTableComponent } from './components/customer/customer-table/customer-table.component';
import { InviteDialogComponent } from './components/invite-dialog/invite-dialog.component';
import { LandingComponent } from './components/landing/landing.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ResetDialogComponent } from './components/reset-dialog/reset-dialog.component';
import { AppInterceptor } from './interceptors/app.inteceptor';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;


export function loggerCallback(logLevel: LogLevel, message: string) {
  // console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.auth.clientId,
      authority: environment.msalConfig.auth.authority,
      redirectUri: environment.postLogoutUrl,
      postLogoutRedirectUri: environment.postLogoutUrl
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = getProtectedResourcesMap();

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    // authRequest: {
    //   scopes: []
    // },
    loginFailedRoute: '/login'
  };
}


const getProtectedResourcesMap = (): Map<string, Array<string>> => {
  const ret = new Map([['https://graph.microsoft.com/v1.0/me', ['user.read']],
  [environment.apiUrl, ['api://3b2fef0b-c58d-4ff6-bca9-f2c0c0a9e6b8/vppadminapi.all']]]);
  return ret;
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LandingComponent,
    CustomerTableComponent,
    CustomerFormComponent,
    InviteDialogComponent,
    ResetDialogComponent,
    LoaderComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    InputTextModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    TableModule,
    MultiSelectModule,
    TagModule,
    DropdownModule,
    SliderModule,
    ProgressBarModule,
    CalendarModule,
    RippleModule,
    ConfirmDialogModule,
    ToastModule,
    DynamicDialogModule,
    HttpClientModule,
    ProgressSpinnerModule,
    ClipboardModule,
    MsalModule,
  ],
  providers: [
    ConfirmationService,
    MessageService,
    DialogService,
    DynamicDialogRef,
    ClipboardService,
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
    { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
    { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: MSALInterceptorConfigFactory },
    AppInterceptor,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    MsalInterceptor,
    MessageService,
  ],
  bootstrap: [AppComponent, AppMsalRedirectComponent],
})
export class AppModule { }
