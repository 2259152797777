export const environment = {
  production: false,
  apiUrl: 'https://api-managment-digitalizcija.azure-api.net/vppadmin-api-test',
  name:'test environment',
  postLogoutUrl: 'https://vpp-admin.test.valamar.hr',

  msalConfig: {
    auth: {
      clientId: '04899e56-d1ea-47f7-9386-d2165939faa3',
      authority: 'https://login.microsoftonline.com/c7223f2c-1ba2-43c8-be7f-57e6e1465036'
    }
  },
  apiConfig: {
    scopes: ['user.Read'],
    uri: 'https://graph.microsoft.com'
  },
  APIM: {
    SubscriptionKeyName: 'Ocp-Apim-Subscription-Key',
    SubscriptionKeyValue: 'e476f806a7e7406994114eb1d369f517'
  },
}
