import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {map, Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {
  IPartnerResponse,
  IResetPasswordRequest,
  IValamarPartner
} from "../../components/customer/interfaces/customer-valamar-interface";
import {IInvitationEmail} from "../../components/invite-dialog/interfaces/invite.email.interface";
import {IEmailResponse} from "../interfaces/email-response";

// const token = localStorage.getItem('token');
// const id_token = token != null ? JSON.parse(token) : '';
// const headers = {
//   Authorization: 'Bearer ' + id_token,
// };

@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  private apiUrl = `${environment.apiUrl}`;
  constructor(
    private httpClient: HttpClient,
    private route: ActivatedRoute,
  ) {}


  private token = localStorage.getItem('token');
  private id_token = this.token != null ? JSON.parse(this.token) : '';
  private SubscriptionKeyName = `${environment.APIM.SubscriptionKeyName}`;
  private SubscriptionKeyValue = `${environment.APIM.SubscriptionKeyValue}`;

  private headers = {
     [this.SubscriptionKeyName]: this.SubscriptionKeyValue,
     Authorization: 'Bearer ' + this.id_token,
  };


  getAllPartners (): Observable<IPartnerResponse> {
    // const headers = this.headers;
    return this.httpClient.get<IPartnerResponse>(`${this.apiUrl}/api/Partner/partner?isActive=true`);//, { headers } );
  }

  updatePartner(body: IValamarPartner) {
    const headers = this.headers;
    return this.httpClient.put<IValamarPartner>(`${this.apiUrl}/api/Partner/partner/update`, body);//, { headers } );
  }


  deletePartner(partner:IValamarPartner) {
    const headers = this.headers;
    return this.httpClient.put<IValamarPartner>(`${this.apiUrl}/api/Partner/partner/update`, partner);//, { headers } );
  }

  findPartnerByOib(oib?: string): Observable<IValamarPartner[]> {
    const headers = this.headers;
    return this.httpClient.get<IPartnerResponse>(`${this.apiUrl}/api/Partner/partner?isActive=true`)//, { headers } )
      .pipe(
        map(data => data.partners)
      )
  }

  resetPassword(body: IResetPasswordRequest):Observable<IEmailResponse> {
    const headers = this.headers;
    return this.httpClient.post<IEmailResponse>(`${this.apiUrl}/api/Partner/partner/email/resetPassword`, body);//, { headers } );
  }

  sendInvitationMail(body: IInvitationEmail):Observable<IEmailResponse> {
    const headers = this.headers;
    return this.httpClient.post<IEmailResponse>(`${this.apiUrl}/api/Partner/partner/email/invitation`, body);//, { headers } );
  }

}
