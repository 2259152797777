import {AbstractControl, AsyncValidatorFn, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {PartnerService} from "../../../../services/api/partner.service";
import {debounceTime, defer, filter, map, share, shareReplay, switchMap} from "rxjs";

export function propertyExistsValidatorOnFormControl(partner: PartnerService):AsyncValidatorFn  {
  return (control: AbstractControl) => {
    return partner.findPartnerByOib(control.value)
      .pipe(
        map(data => {
          return data.find(partner => {
            return (partner.oib === control.value);
          })
        }),
        map(partner => partner ? { partnerExists: true } : null)
      );
  }
}

/**
 * *
 * @param partner service for getting data
 */
export function oibExistsValidator(partner: PartnerService):AsyncValidatorFn  {
  return (form: AbstractControl) => {
    return partner.findPartnerByOib()
      .pipe(
        // share(),
        // shareReplay(),
        map(partners => {
            const masterId = form.get("masterId")?.value;
            return partners.filter(partner => partner.masterId !== masterId)
        }),
        map(partners => {
          const oib = form.get('oib')?.value;
          return partners.find(partner => {
            return partner.oib === oib;
          })
        }),
        map(partner => partner ? { partnerExists: true } : null),
      );
  }
}

export function ArCodeValidator(): ValidatorFn {
  return (form: AbstractControl): ValidationErrors | null => {
    const code: string = form.get("arCode")?.value;
    const isApproved:string = form.get("isApproved")?.value;
    if ((code === null || code === undefined || code === '') && isApproved) {
      return { codeRequired:true };
    }
    return null;
  }
}
