<div class="table-wrapper">
  <app-loader *ngIf="partners === null">
  </app-loader>
  <p-table
    #dt1
    [value]="partners"
    dataKey="masterId"
    [rows]="10"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[10, 25, 50]"
    [loading]="loading"
    [paginator]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [globalFilterFields]="globalFilterFields"
  >
    <ng-template pTemplate="caption">
      <div class="caption-section">

        <div class="invite-wrapper">
          <p-button label="Pozivnica" styleClass="p-button-outlined p-button-info" (click)="inviteCustomer()" class="invite-btn"></p-button>
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              (input)="dt1.filterGlobal($any($event.target).value, 'contains')"
              placeholder="Pretraga"
            />
          </span>
          <p-button class="button-space" (click)="loadPartners()" label="Osvježi podatke"></p-button>
        </div>
        <p-button label="izlaz iz aplikacije" icon="pi pi-sign-out" (click)="logout()" ></p-button>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
<!--        <th><button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear(dt1)"></button></th>-->
        <th></th>
        <th><p-button styleClass="p-button-raised p-button-text" icon="pi pi-sort-alt-slash" (click)="clear(dt1)"></p-button></th>
        <th style="min-width: 12rem;" pSortableColumn="isApproved">
          <div class="flex align-items-center">
            Autoriziran
            <p-sortIcon field="isApproved"></p-sortIcon>
            <p-columnFilter field="status" matchMode="equals" display="menu">
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback"
              >
                <p-dropdown
                  [ngModel]="value"
                  [options]="statuses"
                  (onChange)="filter($event.value)"
                  placeholder="Any"
                >
                  <ng-template let-option pTemplate="item">
                    <p-tag
                      [value]="option.value"
                      [severity]="getSeverity(option.label)"
                    ></p-tag>
                  </ng-template>
                </p-dropdown>
              </ng-template>
            </p-columnFilter>
          </div>
        </th>
        <th style="min-width: 9rem;" pSortableColumn="name">
          <div class="flex align-items-center">
            Naziv
            <p-sortIcon field="name"></p-sortIcon>
            <p-columnFilter
              type="text"
              field="name"
              display="menu"
            ></p-columnFilter>

          </div>
        </th>
        <th pSortableColumn="email">
          <div class="flex align-items-center">
            Email
            <p-sortIcon field="email"></p-sortIcon>
            <p-columnFilter
              type="text"
              field="email"
              display="menu"
            ></p-columnFilter>
          </div>
        </th>
        <th style="min-width: 8rem;" pSortableColumn="arCode">
          <div class="flex align-items-center">
            Kod
            <p-sortIcon field="arCode"></p-sortIcon>
            <p-columnFilter
              type="text"
              field="arCode"
              display="menu"
            ></p-columnFilter>
          </div>
        </th>
        <th style="min-width: 10rem;" pSortableColumn="address">
          <div class="flex align-items-center">
            Adresa
            <p-sortIcon field="address"></p-sortIcon>
            <p-columnFilter
              type="text"
              field="address"
              display="menu"
            ></p-columnFilter>
          </div>
        </th>
        <th style="min-width: 8rem;" pSortableColumn="postalCode">
          <div class="flex align-items-center">
            PPT
            <p-sortIcon field="postalCode"></p-sortIcon>
            <p-columnFilter
              type="text"
              field="postalCode"
              display="menu"
            ></p-columnFilter>
          </div>
        </th>
        <th style="min-width: 9rem;" pSortableColumn="city">
          <div class="flex align-items-center">
            Grad
            <p-sortIcon field="city"></p-sortIcon>
            <p-columnFilter
              type="text"
              field="city"
              display="menu"
            ></p-columnFilter>
          </div>
        </th>
        <th style="min-width: 10rem;" pSortableColumn="country">
          <div class="flex align-items-center">
            Drzava
            <p-sortIcon field="country"></p-sortIcon>
            <p-columnFilter
              type="text"
              field="country"
              display="menu"
            ></p-columnFilter>
          </div>
        </th>
        <th pSortableColumn="oib">
          <div class="flex align-items-center">
            OIB
            <p-sortIcon field="oib"></p-sortIcon>
            <p-columnFilter
              type="text"
              field="oib"
              display="menu"
            ></p-columnFilter>
          </div>
        </th>
        <th>
          <div class="flex align-items-center">
            Tel
            <p-columnFilter
              type="text"
              field="phone"
              display="menu"
            ></p-columnFilter>
          </div>
        </th>
        <th pSortableColumn="url">
          <div class="flex align-items-center">
            Web
            <p-sortIcon field="url"></p-sortIcon>
            <p-columnFilter
              type="text"
              field="url"
              display="menu"
            ></p-columnFilter>
          </div>
        </th>
        <th style="min-width: 12rem;" pSortableColumn="responsiblePerson">
          <div class="flex align-items-center">
            Odg. osoba
            <p-sortIcon field="responsiblePerson"></p-sortIcon>
            <p-columnFilter
              type="text"
              field="responsiblePerson"
              display="menu"
            ></p-columnFilter>
          </div>
        </th>
        <th style="min-width: 12rem;" pSortableColumn="bankAccount.bank">
          <div class="flex align-items-center">
            Naziv banke
            <p-sortIcon field="bankAccount.bank"></p-sortIcon>
            <p-columnFilter
              type="text"
              field="bank"
              display="menu"
            ></p-columnFilter>
          </div>
        </th>
        <th style="min-width: 14rem;" pSortableColumn="bankAccount.centralOffice">
          <div class="flex align-items-center">
            Sjedište banke
            <p-sortIcon field="bankAccount.centralOffice"></p-sortIcon>
            <p-columnFilter
              type="text"
              field="centralOffice"
              display="menu"
            ></p-columnFilter>
          </div>
        </th>
        <th style="min-width: 10rem;">
          <div class="flex align-items-center">
            IBAN
            <p-columnFilter
              type="text"
              field="iban"
              display="menu"
            ></p-columnFilter>
          </div>
        </th>
        <th></th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-customer let-expanded="expanded">
      <tr>
        <td>
          <button
            type="button"
            pButton
            pRipple
            [pRowToggler]="customer"
            class="p-button-text p-button-rounded p-button-plain"
            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
          ></button>
        </td>
        <td>
          <button
            type="button"
            pButton
            pRipple
            class="p-button-text p-button-rounded p-button-plain p-button"
            (click)="copyToClipboard(customer)"
            [icon]="'pi pi-copy'"
          >
          </button>


        </td>
        <td>
          <p-tag
            [value]="getAuthorization(customer.isApproved)"
            [severity]="getSeverity(customer.isApproved)"
          ></p-tag>
        </td>
        <td>
          {{ customer.name }}
        </td>
        <td>
          <span class="ml-1 vertical-align-middle">{{
            customer.email
          }}</span>
        </td>
        <td>
          <span class="ml-1 vertical-align-middle">{{
            customer.arCode
          }}</span>
        </td>
        <td>
          {{ customer?.address }}
        </td>
        <td>
          {{ customer?.postalCode }}
        </td>
        <td>
          {{ customer?.city }}
        </td>
        <td>
          {{ customer?.country }}
        </td>
        <td>
          {{ customer?.oib }}
        </td>
        <td>
          {{ customer?.phone }}
        </td>
        <td>
          {{ customer?.url }}
        </td>
        <td>
          {{ customer?.responsiblePerson}}
        </td>
        <td>
          {{ customer?.bankAccount?.bank}}
        </td>
        <td>
          {{ customer?.bankAccount?.centralOffice}}
        </td>
        <td>
          {{ customer?.bankAccount?.iban}}
        </td>
        <td>
          <p-button label="Reset" styleClass="p-button-outlined p-button-warning p-button-sm" (click)="resetPassword(customer)"></p-button>
        </td>
        <td>
          <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning p-button-sm" (click)="deleteCustomer(customer)"></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7">No customers found.</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-customer>
      <tr>
        <td colspan="8">
          <app-customer-form [customer]="customer" (partnerUpdated)="onPartnerUpdate(true)"></app-customer-form>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-toast></p-toast>
  <p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
</div>
