import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

export const vppAuthGuard: CanActivateFn =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const _authService = inject(MsalService);
    const _r = inject(Router);
    const roles: Array<String> = _authService.instance.getAllAccounts().map(x => (x.idTokenClaims as any).roles).reduceRight((_a, b) => b, null);
    if (roles && roles.filter(x => route.data['Roles'].includes(x)).length > 0) {
      return true;
    }
    localStorage.clear();
    _r.navigate(['login']);
    return false;
  };
