<form [formGroup]="formGroup">
  <div class="label-data">
    <label>Naziv</label>
    <input type="text" [ngClass]="{'validator-input': name.errors}" pInputText formControlName="name" />
    <span *ngIf="name.errors" class="validator">
      naziv mora imati vrijednost
    </span>
  </div>

  <div class="label-data">
    <label>Email</label>
    <input type="text" [ngClass]="{'validator-input': email.errors}" pInputText formControlName="email" />
    <span *ngIf="email.errors" class="validator">
      polje email mora biti u odgovarajućem formatu
    </span>
  </div>

  <div class="label-data">
    <label>Adresa</label>
    <input type="text" [ngClass]="{'validator-input': address.errors}" pInputText formControlName="address" />
    <span *ngIf="address.errors" class="validator">
      polje adresa mora imati vrijednost
    </span>
  </div>
  <div class="label-data">
    <label>PPT</label>
    <input type="text" [ngClass]="{'validator-input': postalCode.errors}" pInputText formControlName="postalCode" />
    <span *ngIf="postalCode.errors" class="validator">
      polje PPT mora imati vrijednost
    </span>
  </div>

  <div class="label-data">
    <label>Kod</label>
    <input type="text" [ngClass]="{'validator-input': formGroup.errors?.['codeRequired']}" pInputText formControlName="arCode" />
    <span *ngIf="formGroup.errors?.['codeRequired']" class="validator">
      polje kod mora imati vrijednost ako je partner autoriziran
    </span>
  </div>

  <div class="label-data">
    <label>Grad</label>
    <input type="text" [ngClass]="{'validator-input': city.errors}" pInputText formControlName="city" />
    <span *ngIf="city.errors" class="validator">
      polje grad mora imati vrijednost
    </span>
  </div>
  <div class="label-data">
    <label>Država</label>
    <p-dropdown
      formControlName="country"
      [options]="countries"
      optionLabel="name"
      optionValue="code"
      [panelStyle]="{ 'min-width': 'min(100vw, 300px)' }"
      [virtualScroll]="true"
      [virtualScrollItemSize]="30"
      [filter]="true"
    >
    </p-dropdown>
  </div>
  <div class="label-data">
    <label>OIB</label>
    <input type="text" [ngClass]="{'validator-input': formGroup.errors?.['partnerExists'] || oib.errors }" pInputText formControlName="oib"/>
    <span *ngIf="formGroup.errors?.['partnerExists']" class="validator">
      oib mora biti jedinstven
    </span>
    <span *ngIf="oib.errors" class="validator">
      oib mora imati vrijednost
    </span>
  </div>

  <div class="label-data">
    <label>Tel</label>
    <input type="text" [ngClass]="{'validator-input': phone.errors}" pInputText formControlName="phone" />
    <span *ngIf="phone.errors" class="validator">
      polje telefon mora imati vrijednost
    </span>
  </div>
  <div class="label-data">
    <label>Web</label>
    <input type="text" [ngClass]="{'validator-input': url.errors}" pInputText formControlName="url" />
    <span *ngIf="url.errors" class="validator">
      polje web mora imati vrijednost
    </span>
  </div>
  <div class="label-data">
    <label>Odg. osoba</label>
    <input type="text" [ngClass]="{'validator-input': responsiblePerson.errors}" pInputText formControlName="responsiblePerson" />
    <span *ngIf="responsiblePerson.errors" class="validator">
      polje odgovorna osoba mora imati vrijednost
    </span>
  </div>


    <div class="label-data" formGroupName="bankAccount">
      <label>Naziv banke</label>
      <input type="text" [ngClass]="{'validator-input': bank.errors}" pInputText formControlName="bank" />
      <span *ngIf="bank.errors" class="validator">
      polje naziv banke mora imati vrijednost
    </span>
    </div>

    <div class="label-data" formGroupName="bankAccount">
      <label>Sjediste banke</label>
      <input type="text" [ngClass]="{'validator-input': bank.errors}" pInputText formControlName="centralOffice" />
      <span *ngIf="centralOffice.errors" class="validator">
      polje sjedište banke mora imati vrijednost
    </span>
    </div>

    <div class="label-data" formGroupName="bankAccount">
      <label>iban</label>
      <input type="text" [ngClass]="{'validator-input': iban.errors}" pInputText formControlName="iban" />
      <span *ngIf="iban.errors" class="validator">
      polje iban mora imati vrijednost
    </span>
    </div>


  <div class="label-data">
    <label>Autoriziran</label>
    <p-dropdown
      class="autoriziran"
      formControlName="isApproved"
      [options]="approved"
      optionLabel="label"
      optionValue="value"
      [panelStyle]="{ 'min-width': 'min(100vw, 250px)', 'height': '10px'}"
      [virtualScroll]="true"
      [virtualScrollItemSize]="30"
    ></p-dropdown>
  </div>
  <br>
  <div class="button-update">
    <p-button label="Ažuriraj" (click)="updatePartner()" [disabled]="!formGroup.valid"></p-button>
    <input type="hidden" formControlName="masterId">
  </div>

</form>
