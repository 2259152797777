import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormControl, FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { approved } from '../statuses';
import { IValamarPartner } from "../interfaces/customer-valamar-interface";
import { Validators } from "@angular/forms";
import { ICountries } from "./interfaces/country-interface";
import { countries } from "./countries-data";
import { PartnerService } from "../../../services/api/partner.service";
import {catchError, map, of, throwError} from "rxjs";
import { MessageService } from "primeng/api";
import {oibExistsValidator, ArCodeValidator } from "./validation/validation";

@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss'],
})
export class CustomerFormComponent implements OnInit {

  @Input() customer!: IValamarPartner;

  @Output()
  partnerUpdated = new EventEmitter<boolean>();
  approved = approved;
  countries: ICountries[] = countries;

  formGroup = new FormGroup({
      masterId: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      arCode: new FormControl(''),
      address: new FormControl('',[Validators.required]),
      postalCode: new FormControl('',[Validators.required]),
      city: new FormControl('',[Validators.required]),
      country: new FormControl('',[Validators.required]),
      oib: new FormControl('',[Validators.required]),
      phone: new FormControl('',[Validators.required]),
      url: new FormControl('',[Validators.required]),
      responsiblePerson: new FormControl('',[Validators.required]),
      bankAccount: new FormGroup({
        bank: new FormControl('', [Validators.required]),
        centralOffice: new FormControl('', [Validators.required]),
        iban: new FormControl('', [Validators.required]),
      }),
      isApproved: new FormControl(),
  }, {
    asyncValidators: oibExistsValidator(this.partnerService),  updateOn: 'change',
    validators: ArCodeValidator()
  });

  get masterId(): any {
    return this.formGroup.controls['masterId'];
  }

  get name() {
    return this.formGroup.controls['name'];
  }

  get email() {
    return this.formGroup.controls['email'];
  }
  get address() {
    return this.formGroup.controls['address'];
  }

  get city() {
    return this.formGroup.controls['city'];
  }
  get country() {
    return this.formGroup.controls['country'];
  }

  get postalCode() {
    return this.formGroup.controls['postalCode'];
  }

  get phone() {
    return this.formGroup.controls['phone'];
  }

  get url() {
    return this.formGroup.controls['url'];
  }

  get responsiblePerson() {
    return this.formGroup.controls['responsiblePerson'];
  }

  get oib() {
    return this.formGroup.controls['oib'];
  }

  get arCode() {
    return this.formGroup.controls['arCode'];
  }

  get isApproved() {
    return this.formGroup.controls['isApproved'];
  }

  get bank() {
    return this.formGroup.controls['bankAccount'].controls['bank'];
  }

  get centralOffice() {
    return this.formGroup.controls['bankAccount'].controls['centralOffice'];
  }

  get iban() {
    return this.formGroup.controls['bankAccount'].controls['iban'];
  }

  constructor(
      private partnerService: PartnerService,
      public messageService: MessageService,
      private formBuilder: FormBuilder
  ) {
  }

  ngOnInit() {
    this.formGroup.patchValue({
      ...this.customer,
    });
  }

  updatePartner() {
    const body: any = this.formGroup.value;
    // const body: IValamarPartner = this.formGroup.value;
    this.partnerService.updatePartner(body)
        .pipe(
            map(data => {
              return data;
            }),
            catchError(err => {
              this.messageService.add({severity:'error', summary: 'Došlo je do greške prilikom ažuriranja partnera', detail: ' ' + body.name, life: 3000});
              return throwError(err);
            })
        )
        .subscribe(data => {
          this.messageService.add({severity:'success', summary: 'Uspešno ažuriran partner ', detail: ' ' + body.name, life: 3000});
          this.partnerUpdated.emit(true);
        });
  }

}
