import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { ClipboardService } from "ngx-clipboard";
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { Table } from 'primeng/table';
import {
  catchError,
  map,
  of,
  take,
  tap,
  throwError
} from 'rxjs';
import { environment } from "../../../../environments/environment";
import { PartnerService } from "../../../services/api/partner.service";
import { InviteDialogComponent } from "../../invite-dialog/invite-dialog.component";
import { ResetDialogComponent } from "../../reset-dialog/reset-dialog.component";
import { valamarCustomersTableData } from '../customers-mock-data-valamar';
import { IResetPasswordRequest, IValamarPartner } from '../interfaces/customer-valamar-interface';
import { IStatus } from '../interfaces/status.interface';

@Component({
  selector: 'app-customer-table',
  templateUrl: './customer-table.component.html',
  styleUrls: ['./customer-table.component.scss'],
})
export class CustomerTableComponent implements OnInit {

  private apiUrl = `${environment.apiUrl}`;
  // dataSource$!: Observable<IValamarPartner[]>;
  customers: IValamarPartner[] = valamarCustomersTableData;
  statuses: IStatus[] = [];
  loading: boolean = false;
  activityValues: number[] = [0, 100];
  partners: any = null;

  globalFilterFields = [
    'name',
    'email',
    'country',
    'arCode',
    'address',
    'postalCode',
    'city',
    'country',
    'oib',
    'isApproved',
    'bank',
    'centralOffice'
  ];
  constructor(
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private _authService: MsalService,
    private partnerService: PartnerService,
    private clipboardService: ClipboardService
  ) { }

  ngOnInit() {
    this.loadPartners();
  }

  loadPartners() {
    this.partnerService.getAllPartners().pipe(
      take(1),
      tap((data) => {
        this.partners = data.partners;
        // return data.partners;
      }),
      catchError(err => {
        this.messageService.add({ severity: 'error', summary: 'Došlo je do greške na serveru', detail: 'Molim vas pokušajte kasnije ', life: 3000 })
        return of([]);
      })
    ).subscribe();
  }

  getAuthorization(isAutorized: boolean): string {
    return isAutorized ? 'da' : 'ne';
  }


  clear(table: Table) {
    table.clear();
  }

  getSeverity(status: boolean): 'success' | 'danger' {
    if (status) {
      return 'success'
    } return 'danger';
  }

  deleteCustomer(customer: IValamarPartner) {
    this.confirmationService.confirm({
      message: 'Jeste li sigurni da želite da izbrišete ' + customer.name + '?',
      header: 'Potvrdi',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const masterId = customer.masterId;
        if (masterId !== undefined && masterId !== null && masterId !== '') {
          const deletedCustomer = {
            isActive: false,
            isDeleted: true
          };
          customer = { ...customer, ...deletedCustomer };
          this.partnerService.deletePartner(customer).pipe(
            take(1),
            tap(data => {
              this.messageService.add({ severity: 'success', summary: 'Uspješno izbrisan partner ', detail: '' + customer.name, life: 3000 });
              this.loadPartners();
            }),
            catchError(err => {
              this.messageService.add({ severity: 'error', summary: 'Došlo je do greške prilikom brisanja partnera', detail: ' ' + customer.name, life: 3000 });
              return throwError(err);
            })
          ).subscribe()
        }
      }
    });
  }

  inviteCustomer(customer?: IValamarPartner) {

    this.ref = this.dialogService.open(InviteDialogComponent, {
      header: 'email pozivnica',
      width: '45%',
      height: '55%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
    });

    this.ref.onClose.subscribe((data) => {
      if (data) {
        this.partnerService.sendInvitationMail(data).pipe(
          map(data => {
            return data;
          }),
          catchError(err => {
            this.messageService.add({ severity: 'error', summary: 'Došlo je do greške prilikom slanja email-a na adresu', detail: ' ' + data.to, life: 3000 });
            return throwError(err);
          })
        ).subscribe(data => {
          this.messageService.add({ severity: 'success', summary: 'Pozivnica uspješno poslana  ', life: 3000 });
        })
      }
    });
  }

  resetPassword(customer: IValamarPartner) {

    this.ref = this.dialogService.open(ResetDialogComponent, {
      header: 'zahtjev za resetiranje lozinke',
      width: '45%',
      height: '45%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
      data: {
        customer: customer
      }
    });

    this.ref.onClose.subscribe((data: IResetPasswordRequest) => {
      if (data) {
        this.partnerService.resetPassword(data).pipe(
          map(data => {
            return data;
          }),
          catchError(err => {
            this.messageService.add({ severity: 'error', summary: 'Došlo je do greške prilikom slanja email-a na adresu', detail: ' ' + data.to, life: 3000 });
            return throwError(err);
          })
        ).subscribe(data => {
          this.messageService.add({ severity: 'success', summary: 'Zahtjev za resetiranje lozinke uspješno poslan  ', life: 3000 });
        })
      }
    });
  }

  logout() {
    this._authService.logoutRedirect({
      postLogoutRedirectUri: environment.postLogoutUrl,
    });
  }

  onPartnerUpdate(refresh: boolean) {
    if (refresh) {
      this.loadPartners();
    }
  }

  copyToClipboard(customer: any): void {
    const partnerInfo = `
      Agency: ${customer.name}
      Vat No.: ${customer.oib},
      Person responsibe: ${customer.responsiblePerson},
      Address: ${customer.address},
      City: ${customer.city},
      Country: ${customer.country},
      Telephone: ${customer.phone},
      E-mail: ${customer.email},
      Web Address: ${customer.url},
      Bank: ${customer.bankAccount?.bank},
      Central office: ${customer.bankAccount?.centralOffice},
      IBAN: ${customer.bankAccount?.iban},
    `;
    this.clipboardService.copy(partnerInfo);
    this.messageService.add({ severity: 'success', summary: 'Informacije o partneru kopirane u klipboard', detail: ' ' + customer.name, life: 3000 });
  }

  // loadPartnersStatic() {
  //   this.partnerService.getAllPartners().pipe(
  //     map((data) => {
  //       return data.partners;
  //     }),
  //     catchError(err => {
  //       return throwError(err);
  //     }),
  //     catchError(err => {
  //       this.messageService.add({ severity: 'error', summary: 'Došlo je do greške na serveru', detail: 'Molim vas pokušajte kasnije ', life: 3000 })
  //       return of([]);
  //     }),
  //     shareReplay()
  //   ).subscribe(data => {
  //     // this.dataSource$ = of(data);
  //   })
  // }

}
