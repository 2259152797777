<form [formGroup]="formGroup">
  <div class="label-data">
    <label>Unesite naziv agencije kojoj želite poslati pozivnicu</label><br>
    <input type="text" pInputText formControlName="name"/><br><br><br>
    <label>Unesite email adresu na koju želite poslati pozivnicu</label><br>
    <input type="text" pInputText formControlName="email"/><br><br><br>
    <label>Izaberite jezik na kome želite poslati pozivnicu </label><br />
    <p-dropdown
      [options]="languageTypes"
      formControlName="type"
      optionLabel="label"
      optionValue="value"
    ></p-dropdown>
  </div>
  <br />
  <br />
  <br />
  <button type="button" pButton icon="pi" (click)="sendMail()" [disabled]="!formGroup.valid">Pošalji pozivnicu</button>
</form>
